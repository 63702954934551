<div class="menu-left">
  <nav class="top-4">
    <div class="pb-8 space-y-1 overflow-x-hidden">
      <button (click)="burgerMenu()" class="text-white hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <a pTooltip="Página inicial" [routerLinkActive]="['text-white']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard']" class="text-white hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Página inicial </span>
      </a>
    </div>

    <div class="pb-8 space-y-1 overflow-x-hidden">
      <div class="mt-3 space-y-1">
        <a pTooltip="Clientes" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core-management/customer']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Clientes </span>
        </a>
      </div>
    </div>

    <div class="pt-5">
      <div class="mt-3 space-y-1">
        <a pTooltip="Cobranças" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core-management/customer/financial/invoice']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Cobranças </span>
        </a>
        <a pTooltip="Compras" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core-management/customer/order']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Compras </span>
        </a>
      </div>
    </div>

    <div class="pt-5">
      <a pTooltip="Configurações" [routerLinkActive]="['text-gray-50']" [routerLink]="['/guard/core']" class="text-white hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path fill="#4f46e5" d="M18.75 12.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM12 6a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 6ZM12 18a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 18ZM3.75 6.75h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM5.25 18.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5ZM3 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3 12ZM9 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM12.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Configurações </span>
      </a>
      <a pTooltip="Blog" [routerLinkActive]="['text-gray-50']" [routerLink]="['/guard/core/article']" class="text-white hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Blog </span>
      </a>
      <a pTooltip="Páginas" [routerLinkActive]="['text-gray-50']" [routerLink]="['/guard/core/page']" class="text-white hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Páginas </span>
      </a>
      <a pTooltip="Feriados" [routerLinkActive]="['text-gray-50']" [routerLink]="['/guard/core/holiday']" class="text-white hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium">
        <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path
            d="m15 1.784-.796.795a1.125 1.125 0 1 0 1.591 0L15 1.784ZM12 1.784l-.796.795a1.125 1.125 0 1 0 1.591 0L12 1.784ZM9 1.784l-.796.795a1.125 1.125 0 1 0 1.591 0L9 1.784ZM9.75 7.547c.498-.021.998-.035 1.5-.042V6.75a.75.75 0 0 1 1.5 0v.755c.502.007 1.002.021 1.5.042V6.75a.75.75 0 0 1 1.5 0v.88l.307.022c1.55.117 2.693 1.427 2.693 2.946v1.018a62.182 62.182 0 0 0-13.5 0v-1.018c0-1.519 1.143-2.829 2.693-2.946l.307-.022v-.88a.75.75 0 0 1 1.5 0v.797ZM12 12.75c-2.472 0-4.9.184-7.274.54-1.454.217-2.476 1.482-2.476 2.916v.384a4.104 4.104 0 0 1 2.585.364 2.605 2.605 0 0 0 2.33 0 4.104 4.104 0 0 1 3.67 0 2.605 2.605 0 0 0 2.33 0 4.104 4.104 0 0 1 3.67 0 2.605 2.605 0 0 0 2.33 0 4.104 4.104 0 0 1 2.585-.364v-.384c0-1.434-1.022-2.7-2.476-2.917A49.138 49.138 0 0 0 12 12.75ZM21.75 18.131a2.604 2.604 0 0 0-1.915.165 4.104 4.104 0 0 1-3.67 0 2.605 2.605 0 0 0-2.33 0 4.104 4.104 0 0 1-3.67 0 2.605 2.605 0 0 0-2.33 0 4.104 4.104 0 0 1-3.67 0 2.604 2.604 0 0 0-1.915-.165v2.494c0 1.035.84 1.875 1.875 1.875h15.75c1.035 0 1.875-.84 1.875-1.875v-2.494Z"
          />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Feriados </span>
      </a>
    </div>

    <div class="pt-5">
      <div class="mt-3 space-y-1">
        <a pTooltip="F.A.Q" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core/application/faq']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> F.A.Q </span>
        </a>
        <a pTooltip="Atendimento" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core-management/customer/ticket']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
            />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Atendimento </span>
        </a>
      </div>
    </div>

    <div class="pt-5">
      <div class="mt-3 space-y-1">
        <a pTooltip="Planos" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core/plan']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Planos </span>
        </a>
        <a pTooltip="Cupom de Desconto" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core/voucher']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Cupom de Desconto </span>
        </a>
      </div>
    </div>

    <div class="pt-10">
      <div class="mt-3 space-y-1">
        <a pTooltip="Usuários" [routerLinkActive]="['text-gray-50']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/core-management/manager/user']" class="group flex items-center px-3 py-1 text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50">
          <svg class="text-white group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> Usuários </span>
        </a>
      </div>
    </div>
  </nav>
</div>

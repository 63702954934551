import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { DateService } from '~services/date.service';
// ]
@Injectable()
export class ManagerTeammateUserService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public dateService: DateService) {
    this.authenticated_user = JSON.parse(localStorage.getItem('teammate_user'));
  }

  userSubject = new ReplaySubject();
  setUser(objectData: Object) {
    this.userSubject.next(JSON.stringify(objectData));
  }

  getUser() {
    return this.userSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getData(data: any) {
    return this.getPost(data);
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};

    if (data instanceof FormData) {
      data.forEach((value, key, formData) => {
        postData[key] = this.getPostParseData(value);
      });

      return postData;
    } else {
      for (let key in data) {
        postData[key] = this.getPostParseData(data[key]);
      }

      return postData;
    }
  }

  /**
   *
   * @param data
   */
  getPostFormData(data: any) {
    return data;
  }

  /**
   *
   * @param value
   */
  getPostParseData(value: any) {
    if (Array.isArray(value)) {
      return { ...value };
    } else {
      if (typeof value == 'object') {
        if (value == null) {
          return null;
        } else if (Object.prototype.toString.call(value) === '[object Date]') {
          return this.dateService.convertDatePickerTimeToMySQLTime(value);
        } else if (Object.prototype.toString.call(value) === '[object File]') {
          return value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'manager/teammate?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'manager/teammate/' + data.id + '?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  store(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.post(environment.api_url + 'manager/teammate', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'manager/teammate/' + data.id, postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  destroy(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.delete(environment.api_url + 'manager/teammate/' + data.id, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_password(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'manager/teammate/' + data.id + '/update_password', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_login(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'manager/teammate/' + data.id + '/update_login', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_avatar(data: any) {
    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.post(environment.api_url + 'manager/teammate/' + data.id + '/avatar', data.upload, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  logout(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/customer/logout', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   */
  profile() {
    let tk = this.authenticated_user == null ? null : this.authenticated_user.api_token;
    let id = this.authenticated_user == null ? null : this.authenticated_user.id;
    if (tk == null || id == null) {
      return of(null);
    }
    return this.http
      .get(environment.api_url_account + 'manager/teammate/' + id, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + tk,
        },
      })
      .pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            localStorage.removeItem('teammate_user');
            return throwError(error);
          }
          if (error.status === 412 && error.error.message == 'Unauthenticated.') {
            localStorage.removeItem('teammate_user');
            return throwError(error);
          }
        })
      );
  }
}

import { environment } from "src/environments/environment";
import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, Input, ComponentFactoryResolver } from "@angular/core";
import { fromEvent } from "rxjs";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
// [[Providers]]
import { ManagerTeammateUserService } from "~project/services/manager/teammate/user.service";
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: "app-guard-header",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})
export class GuardHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;

  favicon: HTMLLinkElement = document.querySelector("#app_icon");

  actualUrl: any = this.router.url;
  openMenuMobile: any = false;
  openSubMenuMobile: any = false;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    /**
     * Base de uso em Component
     */
    private userService: ManagerTeammateUserService
    /**
     * Services
     */
  ) {
    /**
     * Profile
     *
     * Request inicial do PROFILE para acompanhamento nas Páginas.
     */
    this.userService.profile().subscribe((Profile: any) => {
      if (Profile != null) {
        this.userService.setUser(Profile.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
  }

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildAppDependencies();
  }

  /**
   * build app dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * Users service logout
   */
  userServiceLogout() {
    localStorage.clear();

    let _ = this.userService.logout(this.profile);
    if (_ !== null) {
      _.subscribe(
        () => {
          document.location.href = "/";
        },
        () => {
          document.location.href = "/";
        }
      );
    }
  }
}

import { environment } from "src/environments/environment";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// -----------------------------------------------------------------------------
import { LayoutGuardComponent, LayoutWwwComponent } from "~project/layout";
// -----------------------------------------------------------------------------

const routes: Routes = [
  {
    path: "",
    component: LayoutWwwComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages/module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "section",
    component: LayoutWwwComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-sections/module").then((m) => m.PagesSectionsModule),
      },
    ],
  },
  {
    path: "guard",
    component: LayoutGuardComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-guard/module").then((m) => m.PagesGuardModule),
      },
    ],
  },
  {
    path: "guard/application",
    component: LayoutGuardComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-guard/application/module").then((m) => m.PagesGuardApplicationModule),
      },
    ],
  },
  {
    path: "guard/core",
    component: LayoutGuardComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-guard/core/module").then((m) => m.PagesGuardCoreModule),
      },
    ],
  },
  {
    path: "guard/core-management",
    component: LayoutGuardComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../project/modules/pages-guard/core-management/module").then((m) => m.PagesGuardCoreManagementModule),
      },
    ],
  },
  {
    path: "**",
    component: LayoutWwwComponent,
    loadChildren: () => import("../project/modules/404/module").then((m) => m.Page404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<ng-container *ngIf="profile?.id">
  <div class="main-container flex flex-col mb-4 py-2 bg-white px-2 border-b border-gray-300">
    <div class="relative flex justify-between">
      <div class="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
        <div class="flex-shrink-0 flex items-center">
          <a [routerLink]="['/guard']">
            <img class="block h-10 w-auto ml-5" src="/assets/images/logo.png" alt="iPORTO" />
          </a>
        </div>
      </div>
      <div class="flex items-center justify-end col-span-4 py-4 mx-4 space-x-3">
        <button type="button" (click)="userServiceLogout();" class="text-xs">Sair</button>
        <ng-container *ngIf="profile.url_avatar === null">
          <a [routerLink]="['/guard/profile']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer stroke-gray-600 hover:stroke-gray-900">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </a>
        </ng-container>

        <ng-container *ngIf="profile.url_avatar !== null">
          <a [routerLink]="['/guard/profile']">
            <img class="rounded-full border border-gray-400 block h-10 w-auto mx-2" src="{{profile.url_avatar}}" alt="{{profile.name}}" />
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

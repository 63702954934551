import { Component, OnInit, Input } from '@angular/core';
// [[Providers]]

// ]
// [[Interfaces do Componente]]

// ]
@Component({
  selector: 'app-guard-menu-left',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class GuardLayoutMenuLeftComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  /**
   * The function is called after the view is initialized
   */
  ngAfterViewInit() {
    this.burgerMenuByDevice();
  }

  burgerMenuIsExpanded: boolean = false;
  /**
   * Burgers menu
   */
  burgerMenu() {
    // MENU
    var elements: any = document.getElementsByClassName('menu-left') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = this.burgerMenuIsExpanded ? '150px' : '40px';
    }

    // MAIN
    var elements: any = document.getElementsByClassName('main-container') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.marginLeft = this.burgerMenuIsExpanded ? '150px' : '40px';
    }

    this.burgerMenuIsExpanded = !this.burgerMenuIsExpanded;
  }

  /**
   * Burgers menu by device
   */
  burgerMenuByDevice() {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      this.burgerMenuIsExpanded = false;
    } else {
      this.burgerMenuIsExpanded = false;
    }

    this.burgerMenu();
  }

  burguerMenuSaveStatus() {}
}

import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";
// [[Providers]]
import { ManagerTeammateUserService } from "~project/services/manager/teammate/user.service";
// ]

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: ManagerTeammateUserService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.profile().pipe(
      map((Profile: any) => {
        /**
         * * setUser() para utilizar em outros Componentes.
         */

        if (Profile) {
          return true;
        }

        localStorage.removeItem("teammate_user");
        this.router.navigate(["/section/signin"]);
        return false;
      }),
      catchError(() => {
        localStorage.removeItem("teammate_user");
        this.router.navigate(["/section/signin"]);
        return of(false);
      })
    );
  }
}
